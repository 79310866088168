import React from "react";
import PropTypes from "prop-types";
import { defaultTemplate } from "common/hoc";
import logo from "themes/assets/images/logo-spa.svg";
function DisplayErrorTemplate({ displayErrorViewStore, t }) {
    const { error, onClickHome } = displayErrorViewStore;
    return (
        <React.Fragment>
            <div className="v-not-found">
                <img
                    src={logo}
                    className="c-logo__image"
                    alt="Naki Radio Logo"
                />
                <div className="c-empty-state c-empty-state--404">
                    <i className="u-icon u-icon--xxlrg u-icon--empty-state" />
                    <h3 className="c-empty-state__title">
                        Ooops! Someone cut the cable.
                    </h3>
                    <p className="c-empty-state__desc">
                        {error && (
                        <React.Fragment>
                            {error.message}
                            {error.description && (
                                <span>{error.description}</span>
                            )}
                        </React.Fragment>
                        )}
                    </p>
                    <button
                        className="c-btn c-btn--primary c-btn--base c-btn--ghost"
                        onClick={onClickHome}
                        >
                            {t("DISPLAY_ERROR.BUTTONS.HOME")}
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

DisplayErrorTemplate.propTypes = {
    displayErrorViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(DisplayErrorTemplate);
