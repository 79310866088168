import React from "react";
import { UnauthorizedTemplate } from "themes/common/pages";
import { UnauthorizedViewStore } from "common/pages";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new UnauthorizedViewStore(rootStore, componentProps),
    "unauthorizedViewStore"
)
class Unauthorized extends React.Component {
    render() {
        return <UnauthorizedTemplate {...this.props} />;
    }
}

export default Unauthorized;
