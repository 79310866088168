import { moduleProviderFactory } from "mrb/core";
import { MasterLayout } from "common/layouts";
import { DisplayError, NotFound, Unauthorized } from "common/pages";

(function () {
    moduleProviderFactory.module("master").register({
        name: "master",
        routes: [
            {
                name: "master",
                pattern: "",
                component: MasterLayout,
                children: [
                    {
                        name: "master.not-found",
                        pattern: "/not-found",
                        isPublic: true,
                        component: NotFound,
                    },
                    {
                        name: "master.unauthorized",
                        pattern: "/unauthorized",
                        isPublic: true,
                        component: Unauthorized,
                    },
                    {
                        name: "master.error",
                        pattern: "/error",
                        isPublic: true,
                        component: DisplayError,
                        beforeEnter: (_1, _2, routerStore) => {
                            const {
                                rootStore: { errorStore },
                            } = routerStore;
                            if (!errorStore.error) {
                                return Promise.reject(routerStore.initialState);
                            }
                        },
                        onExit: (_1, _2, routerStore) => {
                            const {
                                rootStore: { errorStore },
                            } = routerStore;
                            errorStore.setError(null);
                        },
                    },
                ],
            },
        ],
    });
})();
