import React from "react";
import PropTypes from "prop-types";
import { defaultTemplate } from "common/hoc";
import logo from "themes/assets/images/logo-spa.svg";

function UnauthorizedTemplate({ unauthorizedViewStore, t }) {
    return (
        <section>
            <div className="v-not-found">
                <img
                    src={logo}
                    className="c-logo__image"
                    alt="Naki Radio Logo"
                />
                <div className="c-empty-state c-empty-state--404">
                    <i className="u-icon u-icon--xxlrg u-icon--empty-state" />
                    <h3 className="c-empty-state__title">
                        Ooops! Someone cut the cable.
                    </h3>
                    <p className="c-empty-state__desc">
                        {t("UNAUTHORIZED.MESSAGE")}
                    </p>
                    <button
                        className="c-btn c-btn--primary c-btn--base c-btn--ghost"
                        onClick={unauthorizedViewStore.onClickHome}
                    >
                        {t("UNAUTHORIZED.BUTTONS.HOME")}
                    </button>
                </div>
            </div>
        </section>
    );
}

UnauthorizedTemplate.propTypes = {
    unauthorizedViewStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default defaultTemplate(UnauthorizedTemplate);
