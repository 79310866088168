import { computed, makeObservable } from "mobx";
import { MrbBaseViewStore } from "mrb/core";

class DisplayErrorViewStore extends MrbBaseViewStore {
    @computed get error() {
        return this.rootStore.errorStore.error;
    }

    constructor(rootStore) {
        super(rootStore);
        makeObservable(this);
    }

    onClickHome = () => {
        this.rootStore.routerStore.goToDefaultRoute();
    };
}

export default DisplayErrorViewStore;
