import { MrbBaseViewStore } from "mrb/core";

class UnauthorizedViewStore extends MrbBaseViewStore {
    constructor(rootStore) {
        super(rootStore);
    }

    onClickHome = () => {
        this.rootStore.routerStore.goToDefaultRoute();
    };
}

export default UnauthorizedViewStore;
