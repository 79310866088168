import React from "react";
import { NotFoundTemplate } from "themes/common/pages";
import { NotFoundViewStore } from "common/pages";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new NotFoundViewStore(rootStore, componentProps),
    "notFoundViewStore"
)
class NotFound extends React.Component {
    render() {
        return <NotFoundTemplate {...this.props} />;
    }
}

export default NotFound;
