import React from "react";
import { DisplayErrorTemplate } from "themes/common/pages";
import { DisplayErrorViewStore } from "common/pages";
import { setCurrentView } from "mrb/core";

@setCurrentView(
    (rootStore, componentProps) =>
        new DisplayErrorViewStore(rootStore, componentProps),
    "displayErrorViewStore"
)
class DisplayError extends React.Component {
    render() {
        return <DisplayErrorTemplate {...this.props} />;
    }
}

export default DisplayError;
